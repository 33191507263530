<template>
  <div class="Login">
    <div class="Login__info">
      <small>{{$t('login_moderator_title')}}</small>
      <h2 :style="{ color: theme.mainColorText }">{{$t('login_moderator_access_title')}}</h2>
      <div class="Login__info__text">
        <p>{{theme.loginText}}</p>
      </div>
    </div>
    <div class="Login__form">
      <form>
        <div>
          <label>{{$t('login_moderator_email')}}</label>
          <input type="text" v-model="formLogin.email" @keydown="clearErrors">
        </div>
        <div>
          <label>{{$t('password')}}</label>
          <input type="password" v-model="formLogin.password" @keydown="clearErrors">
        </div>
        <Button class="btnModLogin" color="accent" :buttonType="'submit'" :disabled="!formValid" @click="handleLogin($event)">
          {{$t('login_moderator_access')}}
          <i class="fas fa-user-tie button-icon"></i>
          <Spinner v-show="loadingAuth" />
        </Button>
      </form>
      <router-link :to="{ name: 'resetPasswordBackoffice' }">{{$t('lost_password')}}</router-link>
    </div>
    <!--form-errors :showErrors="authenticationError">El email o la contraseña no son correctos.</form-errors-->
    <form-errors :showErrors="authenticationError">{{$t(getAuthenticationErrorMessage())}}</form-errors>
    <email-validator :email="formLogin.email">{{$t('error_need_real_email')}}</email-validator>
  </div>
</template>

<script>
  import {
    USER_CLIENT_AUTH_LOGIN,
    USER_REMOVE_AUTH_ERROR,
    USER_FETCH_PROFILE
  } from '@/store/user.module';
  import { mapGetters } from 'vuex';
  import store from '@/store';
  import Button from '~/components/Button';
  import EmailValidator from '@/components/EmailValidator.vue';
  import FormErrors from '@/components/FormErrors.vue';
  import Spinner from '@/components/Spinner.vue';
  import {
    verifyEmail,
    fetchUserProfile,
  }  from '@/utils';

  export default {
    name: 'ClientLoginPage',

    components: {
      Button,
      EmailValidator,
      FormErrors,
      Spinner
    },

    data() {
      return {
        formLogin: {
          email: '',
          password: '',
          error: false,
        },
        editForm: {
          nickname  : "",
          name      : "",
          surname1  : "",
          email     : "",
          gender    : "",
          bornDate  : "",
          province  : {},
          picture   : "",
          medals    : { bronce: 0, gold: 0, silver: 0 }
        },
        loginSubmit: false,
      };
    },

    computed: {
      ...mapGetters([
        'theme',
        'isAuthenticated',
        'authenticationError',
        'loadingAuth',
        'needs2FA'
      ]),

      formValid() {
        return (
          verifyEmail( this.formLogin.email ) && this.formLogin.password !== ''
        );
      },
    },

    beforeMount(){
      if(this.$route.params.finishLogin) {
        this.finishLogin()
      }
    },

    methods: {
      async handleLogin(e) {
        e.preventDefault();
        if(this.loginSubmit){
          return
        }
        this.loginSubmit = true
        await store.dispatch(USER_CLIENT_AUTH_LOGIN, {
          communityName: this.$route.params.origin,
          loginData: {
            email: this.formLogin.email,
            password: this.formLogin.password,
          },
        })
        if ( this.isAuthenticated ) {
          const origin = this.$route.params.origin;
          if(this.needs2FA){
            this.$router.push( { name: "2FA", params: { origin } } );
            return
          }
          this.finishLogin()
        }
        this.loginSubmit = false
      },

      clearErrors ( e ) {
        if ( this.authenticationError )
          store.commit( USER_REMOVE_AUTH_ERROR );
      },
      async finishLogin(){
        const origin = this.$route.params.origin;
        await fetchUserProfile( this );
        this.$router.push( { name: 'activities', params: { origin } } );
      },
      getAuthenticationErrorMessage(){
        if (this.authenticationError){
          return this.authenticationError.data.error
        }
        else return "null"
      }
    },
  };
</script>

<style lang="scss" scoped>
.btnModLogin{
  width: 172px;
  font-size: 11px;
}
.Login {
  width                 : 100%;


  @media only screen and ( max-width: 769px ) {
    width               : 100%;
    padding             : 1rem 0;
  }

  &__info {
    width               : 75%;
    display             : flex;
    flex-direction      : column;
    align-items         : flex-start;
    margin-left         : 60px;

    h1 {
      font-size         : 25px;
      font-family       : inherit;
      font-weight       : 700;
      margin-bottom     : 0;
      color             : #191919;
    }

    h2 {
      margin            : 0;
      margin-top        : -10px;
      padding           : 0 0 10px 0;
      border-bottom     : 1px solid #e9e9e9;
      font-size         : 40px;
      font-family       : inherit;
      font-weight       : 700;
    }

    p {
      color             : #797979;
      font-size         : 13px;
    }

    &__text {
      padding-top       : 10px;
      padding-bottom    : 10px;
    }
  }

  &__form {
    padding             : 20px 0 20px 60px;
    background          : #ededed;
    display             : flex;
    flex-direction      : column;
    align-items         : flex-start;

    form {
      display           : flex;
      align-items       : flex-end;
      flex-wrap         : wrap;
      margin            : 5px 0;

      .Button {
        margin-top      : 10px;
      }

      div {
        display         : flex;
        flex-direction  : column;
        margin-right    : 10px;

        label {
          font-size     : 12px;
          color         : #494949;
          font-weight   : bold;
        }

        input {
          padding       : 10px;
          margin-top    : 5px;
          border        : none;
          outline       : none;
          box-shadow    : 0px 0px 20px 0px rgba( 0, 0, 0, 0.1 );
          transition    : box-shadow 300ms;

          &:focus {
            box-shadow  : 0px 0px 20px 0px rgba( 0, 0, 0, 0.3 );
          }
        }
      }
    }
    a {
      color             : var( --text-color );
      font-size         : 12px;

      &:hover {
        text-decoration : underline;
      }
    }
  }

  .button-icon{
    margin: auto auto auto 5px;
    font-size: 18px;
  }
}

@media only screen and ( max-width: 769px ) {
  .Login {
    &__info {
      margin            : 1rem auto;
    }
    &__form {
      padding           : 20px;

      form {
        flex-direction  : column;
        width           : 100%;

        .Button--Accent {
          margin-top    : 10px;
          width         : 100%;
          height        : 34px;
          font-size     : 16px;
        }

        div {
          width         : 100%;
          margin-right  : 0;

          input {
            margin      : 5px 0;
          }
        }
      }
    }
  }
}
</style>
